import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="chevron-up"
      className="prefix__svg-inline--fa prefix__fa-chevron-up prefix__fa-w-14"
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="130"
      viewBox="0 0 140 130"
    >
      <path
        d="M62 117C78.0163 117 91 104.016 91 88C91 71.9837 78.0163 59 62 59C45.9837 59 33 71.9837 33 88C33 104.016 45.9837 117 62 117Z"
        fill="#8B5CF6"
        fillOpacity="0.47"
      />
      <path
        d="M117 123C122.523 123 127 118.299 127 112.5C127 106.701 122.523 102 117 102C111.477 102 107 106.701 107 112.5C107 118.299 111.477 123 117 123Z"
        fill="#8B5CF6"
        fillOpacity="0.46"
      />
      <path
        d="M24 11C26.2092 11 28 9.20914 28 7C28 4.79086 26.2092 3 24 3C21.7909 3 20 4.79086 20 7C20 9.20914 21.7909 11 24 11Z"
        fill="#8B5CF6"
      />
      <path
        d="M52.5 117C78.1812 117 99 96.1812 99 70.5C99 44.8188 78.1812 24 52.5 24C26.8188 24 6 44.8188 6 70.5C6 96.1812 26.8188 117 52.5 117Z"
        fill="#8B5CF6"
        fillOpacity="0.4"
      />
      <path
        d="M123.5 130C132.613 130 140 122.613 140 113.5C140 104.387 132.613 97 123.5 97C114.387 97 107 104.387 107 113.5C107 122.613 114.387 130 123.5 130Z"
        fill="#8B5CF6"
        fillOpacity="0.44"
      />
      <path
        d="M27.5 13C31.0899 13 34 10.0899 34 6.5C34 2.91015 31.0899 0 27.5 0C23.9101 0 21 2.91015 21 6.5C21 10.0899 23.9101 13 27.5 13Z"
        fill="#8B5CF6"
        fillOpacity="0.36"
      />
      <path
        d="M43 97C53.4934 97 62 88.4934 62 78C62 67.5066 53.4934 59 43 59C32.5066 59 24 67.5066 24 78C24 88.4934 32.5066 97 43 97Z"
        fill="#8B5CF6"
      />
      <path
        d="M114 123C117.866 123 121 119.866 121 116C121 112.134 117.866 109 114 109C110.134 109 107 112.134 107 116C107 119.866 110.134 123 114 123Z"
        fill="#8B5CF6"
      />
      <path
        d="M21 11C22.6569 11 24 9.65685 24 8C24 6.34315 22.6569 5 21 5C19.3431 5 18 6.34315 18 8C18 9.65685 19.3431 11 21 11Z"
        fill="#8B5CF6"
      />
      <path
        d="M102 14C103.657 14 105 12.6569 105 11C105 9.34315 103.657 8 102 8C100.343 8 99 9.34315 99 11C99 12.6569 100.343 14 102 14Z"
        fill="#8B5CF6"
      />
      <path
        d="M3 53.9999C4.65685 53.9999 6 52.6568 6 50.9999C6 49.3431 4.65685 47.9999 3 47.9999C1.34315 47.9999 0 49.3431 0 50.9999C0 52.6568 1.34315 53.9999 3 53.9999Z"
        fill="#8B5CF6"
      />
    </svg>
  );
};

export default SvgComponent;
