export default {
  // Nav bar
  dataDashboard: '数据仪表板',
  installationGuide: '安装指导',
  apiDoc: 'API文档',
  downloads: '下载',
  search: '搜索',
  selectOrganization: '选择组织',
  noResults: 'No results',
  searchAction:
    'Nothing matches your search. Try different keywords for site, building, floor, room, or zone.',
  delete: 'Delete',
  details: 'Details',
  // Header
  dashboards: '数据报表',
  settings: '设置',
  language: '语言',
  done: '完成',
  toChangePassword: '',
  clickHere: '点击此处修改密码',
  changePassword: '修改密码',
  logOut: '退出',
  learningCenter: '帮助中心',
  contactSupport: '联络技术支持',
  switchOrganization: '切换账号',
  // Main board
  charts: '数据仪表板',
  spaceVisualizer: '视觉仪表板',
  spaceSummary: '空间数据概要',
  entrywayTraffic: '人流量',
  doorwayTraffic: '入口人流量',
  zoneTraffic: '区域人流量',
  in: '进',
  out: '出',
  occupancy: '当前人数',
  roomOccupancy: '空间占用情况',
  dailyEntries: '今日累计到访人数',
  dailyExits: '今日累计离场人数',
  livePresenceCoverage: '实时人员活动情况',
  estOccupancy: '（估算）人数',
  estOccupancyTrend: '（估算）人数趋势',
  occupancyTrend: '人数趋势',
  deskUtilization: '办公桌使用率',
  parentBuilding: 'Parent Building',
  buildingName: 'Building Name',
  moveFloor: 'Changing the Building will move its data (floor, rooms, zones) to the new Site.',
  confirmDeleteBuilding: 'Are you sure you want to delete this building?',
  deleteBuilding: 'This building will be deleted permanently. You can’t undo this action.',
  deleteSite: 'This site will be deleted permanently. You can’t undo this action.',
  confirmDeleteSite: 'Are you sure you want to delete this site?',
  unableToDelete: 'Unable to delete',
  unableToDeleteSite: 'This site has linked spaces. Please remove the spaces first.',
  unableToDeleteBuilding: 'This building has linked spaces. Please remove the spaces first.',
  customBuildingId: 'Custom Building ID',
  beta: '先行版',
  individual: '单入口',
  combined: '综合入口',
  last24Hours: '过去24小时',
  last2Days: '过去2天',
  last7Days: '过去7天',
  last30Days: '过去30天',
  headcountEstimated: '估算总人数',
  roomName: '房间名称',
  '24HTrend': '24小时趋势',
  min: 'Min',
  mid: '容量使用过半',
  max: '容量限制',
  roomSummary: '房间数据概要',
  fromLastWeek: '从1周前',
  totalRoomCapacity: '房间容量',
  spaceCapacity: '空间容量',
  capacity: '容量',
  fromOneHourAgo: '从1小时前',
  now: '现在',
  sites: '场地',
  kits: '套件',
  zoneSummary: '区域数据概要',
  current: '当前',
  // Details panel
  showSpaceDetails: '显示空间信息',
  hideSpaceDetails: '隐藏空间信息',
  showRoomDetails: '显示房间信息',
  hideRoomDetails: '隐藏房间信息',
  showZoneDetails: '显示区域信息',
  hideZoneDetails: '隐藏区域信息',
  spaceInformation: '空间信息',
  roomInformation: '房间信息',
  name: '名称',
  timezone: '时区',
  selectTimezone: 'Please Select a Timezone',
  size: '空间大小',
  id: 'ID',
  unit: '单位',
  capacityLimit: '容量限制',
  capacityWarning: '超员警告',
  occupancyLimit: '人数上限',
  occupancyWarning: '超员警告',
  address: '地址',
  spaceDevices: '空间内设备',
  roomDevices: '房间内设备',
  zoneDevices: '区域内设备',
  headcountSensors: '人流量传感器',
  activitySensors: '活动感应传感器',
  hives: '网关(Hive)',
  editSpaceInformation: '编辑空间信息',
  editRoomInformation: '编辑房间信息',
  editBuildingParentSite:
    'Changing the Site will move its data (building, floor, room, zone) to the new Site..',
  buildingNumber: 'Building Code (Optional)',
  maxCapacity: 'Max Capacity',
  parentSite: 'Parent Site',
  customId: 'Custom ID',
  spaceName: '空间名称',
  area: '面积',
  sqMeters: '平方(米)',
  sqFeet: '平方(英尺)',
  meters: '米',
  feet: '英尺',
  suiteRoom: '街道名称，楼层和房间号等信息',
  city: '城市',
  state: '省区',
  country: '国家',
  zipcode: '邮编',
  typeToSearchCountry: '请输入并搜索国家',
  typeToSearchSite: '请输入并搜索国家',
  typeToSearchTimezone: '请输入并搜索（国家、城市、缩写）',
  cancel: '取消',
  save: '保存',
  edit: '编辑',
  zoneName: '区域名称',
  //QR Scanner
  seeSticker: 'See sticker on sensor for QR Code',
  scanSensor: 'Scan to add sensor',
  typeMAC: 'Type in MAC address',
  connectionHelp: 'Need help connecting your scanner?',
  sensorAlreadyAdded: 'This sensor has already been added in. Please add a different sensor.',
  //Item plural
  sensor: 'Sensor',
  sensor_other: 'Sensors',
  hive: 'Hive',
  hive_other: 'Hives',
  room: 'Room',
  room_other: 'Rooms',
  zone: 'Zone',
  zone_other: 'Zones',
  // Studio Panels
  wrongSpace:
    'This sensor is linked to a different floor. Please remove it from there first and try again.',
  visitSpace: 'Visit Floor.',
  constactSupport: 'Contact Support.',
  wrongClient: 'This sensor is associated with a different account and cannot be added here.',
  pleaseEnterMac: 'Please enter a MAC address to turn it into a live sensor',
  // Space Visualizer
  live: '实时',
  livePresence: '实时人数',
  dailyTraffic: '今日人流量',
  objects: '物件',
  dataCards: '图表',
  dataVisualizations: 'Data Visualizations',
  sensorCoverageAreas: '传感器覆盖范围',
  fixtures: '家具',
  rooms: '房间',
  floorPlanImage: 'Floor Plan Image',
  liveDetections: '人物检测',
  presence: '实时人数模式',
  presenceChart: '实时人数图表',
  estOccupancyChart: '(估算）人数图表',
  hotKeyCheatsheet: '热键指南',
  zoomInOut: '放大/缩小',
  mouseScroll: '滚动鼠标',
  pan: '平移界面',
  orbit: '3D',
  rightMouseDrag: '请按住鼠标右键拖动',
  pleaseSelectATimeRange: 'Please select a time range',
  loadUpTo1WeekOfData: 'Load up to 1 week of data.',
  longerTimeRangeComingSoon: 'Longer time ranges coming soon!',
  generate: 'Generate',
  heatmapPeriodError: 'Please select a time period of 1 week or less',
  spaceVisualizations: 'Space Visualizations',
  fullScreen: 'Full Screen',
  exitFullScreen: 'Exit Full Screen',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  showHide: 'Show/hide',
  keyActions: 'Key actions',
  utilizationHeatmap: 'Utilization Heatmap',
  detectionReplay: 'Detection Replay',
  play: 'Play',
  pause: 'Pause',
  successSpaceVisualizationGeneration: 'Space visualizations are successfully generated.',
  failSpaceVisualizationGeneration: 'Failed to generate space visualizations.',
  pleaseInputMinMax: 'Please input Min/Max',
  // Tooltip
  refreshed: '刷新页面',
  ago: '前',
  secondsAgo: '秒前已刷新页面',
  minutesAgo: '分钟前已刷新页面',
  helpCenter: '支持',
  daily_entries: '主要出入口从午夜0点到现在的人流量，与上周同一时间相比人流量的变化。',
  daily_exits: '主要出入口从午夜0点到现在的人流量，与上周同一时间相比人流量的变化。',
  live_presence_coverage: '所有房间的总人数。',
  occupancy_trend: '所有房间的总人数。',
  live_occupancy_chart: '所有房间的总人数。',
  est_occupancy: '数据基于主要出入口计算。该数据每日午夜0点清零，可能产生小幅统计误差。',
  est_occupancy_trend: '数据基于主要出入口计算。该数据每日午夜0点清零，可能产生小幅统计误差。',
  est_occupancy_chart: '数据基于主要出入口计算。该数据每日午夜0点清零，可能产生小幅统计误差。',
  entryway_traffic_chart: '基于所有人流量传感器 (Headcount Sensor) 的进出数据。',
  doorway_traffic_chart: '基于所有人流量传感器 (Headcount Sensor) 的进出数据。',
  room_occupancy_table: '每个房间的实时人数。',
  space_visualizations_toggle:
    'This feature is currently enabled for spaces with presence sensors only',
  // Units
  second: '秒',
  seconds: '秒',
  minute: '分钟',
  minutes: '分钟',
  date: '日',
  year: '年',
  am: '上午',
  pm: '下午',
  text_direction: 'ltr',

  // Datetime picker
  applyTimeRange: '应用此时间段',
  today: '今天',
  last5Mins: '过去5分钟',
  thisWeek: '这周',
  thisMonth: '这个月',
  thisYear: '今年',
  custom: '自定义',
  quickRanges: '快速选择时间段',
  reset: '重置',
  selectTimeRange: '选择时间段',
  from: '开始',
  until: '结束',
};
