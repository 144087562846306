import * as d3 from 'd3';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { Common } from '@/chart';
import { TStatsOccupancyDP, ECommonVariants, TStatsTrafficDP, ITimeRange } from '@/types';
import { convertTZ, dateToLabel } from '@/utils';

interface OccupancyChartOption {
  container: HTMLDivElement;
  data: TStatsOccupancyDP[];
  capacity: number;
  timeRange: ITimeRange;
  tz: string;
  t: TFunction<'translation', undefined>;
  currentLang: string;
  variant?: ECommonVariants;
  updateCapacityLimit?: (capacity: number) => void;
}

export class OccupancyChart extends Common {
  private data: TStatsOccupancyDP[];
  private capacity: number;
  private normalLineArea!: d3.Selection<SVGPathElement, TStatsOccupancyDP[], null, undefined>;
  private dangerLineArea!: d3.Selection<SVGPathElement, TStatsOccupancyDP[], null, undefined>;
  private dangerLineBoundary!: d3.Selection<SVGLineElement, unknown, null, undefined>;
  private dangerLineLabel!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private line!: d3.Line<TStatsOccupancyDP>;
  private dots!: d3.Selection<SVGCircleElement, TStatsOccupancyDP, SVGSVGElement, unknown>;
  private capacityInput!: d3.Selection<d3.BaseType, unknown, null, undefined>;
  private borderWidth = 1;
  private isCapacityLessThanMax = true;
  private updateCapacityLimit?: (capacity: number) => void;

  constructor(options: OccupancyChartOption) {
    super({
      ...options,
      ...{ shouldDrawVerticalLine: true },
    });
    this.data = options.data;
    this.capacity = options.capacity;
    this.updateCapacityLimit = options.updateCapacityLimit;
  }

  /**
   * Set axes scale
   */
  setAxesScale(): void {
    const { width, height, margin } = this.dimensions;

    // X&Y extents
    let [minX, maxX, maxY] = this.getMinMax();
    this.xExtent = [minX, maxX];
    maxY = maxY + (this.yAxisSegmentCount - (maxY % this.yAxisSegmentCount));
    this.isCapacityLessThanMax = this.capacity <= maxY;
    this.yExtent = [0, maxY];
    // X&Y scales
    this.x = d3
      .scaleTime()
      .domain(this.xExtent)
      .range([margin.left, width - margin.right]);
    this.y = d3
      .scaleLinear()
      .domain(this.yExtent)
      .nice()
      .range([height - margin.bottom, margin.top]);
  }

  /**
   * Set bisect
   */
  setBisect(): void {
    this.bisect = d3.bisector((d: TStatsTrafficDP | TStatsOccupancyDP) =>
      convertTZ(dayjs(d.stop).toDate(), this.tz),
    ).left;
  }

  /**
   * Get min/max for x, y axes
   * @returns [minX, maxX, maxY]
   */
  getMinMax(): [Date, Date, number] {
    return [
      convertTZ(this.timeRange.start.toDate(), this.tz),
      convertTZ(this.timeRange.end.toDate(), this.tz),
      this.data.length ? Math.max(...this.data.map((d) => d.value)) : 0,
    ];
  }

  /**
   * Draw chart
   */
  drawChart() {
    if (!this.data.length) return;

    const { width, height, margin } = this.dimensions;
    const interpolatedData = [
      {
        start: this.data[0].stop,
        stop: this.data[0].stop,
        value: 0,
      },
      ...this.data,
      {
        start: this.data[this.data.length - 1].stop,
        stop: this.data[this.data.length - 1].stop,
        value: 0,
      },
    ]; // Add 0 point for start/end of chart to avoid unexpected filling result

    // Define clip paths
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-all')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', width - margin.right - margin.left)
      .attr('height', height - margin.bottom - margin.top);
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-normal')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', this.isCapacityLessThanMax ? this.y(this.capacity) : margin.top)
      .attr('width', width - margin.right - margin.left)
      .attr(
        'height',
        height - (this.isCapacityLessThanMax ? this.y(this.capacity) : margin.top) - margin.bottom,
      );
    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip-danger')
      .append('rect')
      .attr('x', margin.left)
      .attr('y', margin.top)
      .attr('width', width - margin.right - margin.left)
      .attr('height', this.isCapacityLessThanMax ? this.y(this.capacity) - margin.top : 0);

    this.line = d3
      .line<TStatsOccupancyDP>()
      .defined((d) => !isNaN(d.value))
      .x((d) => this.x(convertTZ(dayjs(d.stop).toDate(), this.tz)) || 0)
      .y((d) => this.y(d.value))
      .curve(d3.curveStepBefore);

    // Draw normal line area
    this.normalLineArea = this.svg
      .insert('path', '.d3-overlay')
      .datum(interpolatedData)
      .attr('class', 'd3-normal-line')
      .attr('fill', '#F1F1F4')
      .attr('clip-path', 'url(#clip-normal)')
      .attr('stroke', '#24252C')
      .attr('stroke-width', this.borderWidth)
      .attr('d', this.line);

    // Draw danger line area
    this.dangerLineArea = this.normalLineArea
      .clone()
      .attr('class', 'd3-danger-line')
      .attr('fill', '#FFF0F0')
      .attr('clip-path', 'url(#clip-danger)')
      .attr('stroke', 'red')
      .attr('stroke-width', this.borderWidth)
      .attr('d', this.line);

    // Draw danger line boundary
    const dangerLineBounday = this.isCapacityLessThanMax ? this.y(this.capacity) : margin.top;
    this.dangerLineBoundary = this.svg
      .append('line')
      .style('stroke-dasharray', '2, 2')
      .attr('stroke-width', 1)
      .attr('x1', margin.left)
      .attr('x2', margin.right + width)
      .attr('y1', dangerLineBounday)
      .attr('y2', dangerLineBounday)
      .attr('stroke', '#F7796E');

    // Draw danger line label
    this.dangerLineLabel = this.svg.append('g').style('display', 'none');

    this.dangerLineLabel
      .append('rect')
      .attr('fill', '#FF0000')
      .attr('x', width / 2 - 55)
      .attr('y', dangerLineBounday - 12)
      .attr('width', 124)
      .attr('height', 24)
      .attr('rx', 10);

    this.dangerLineLabel
      .append('text')
      .text(`${this.t('capacityLimit')}: ${this.capacity}`)
      .attr('x', width / 2 - 55)
      .attr('y', dangerLineBounday - 1)
      .text(`${this.t('capacityLimit')}: `)
      .attr('transform', 'translate(13, 4)')
      .attr('font-size', 10)
      .attr('fill', 'white');

    // Add input for capacity limit
    this.capacityInput = this.svg
      .append('foreignObject')
      .attr('width', 30)
      .attr('height', 30)
      .attr('x', width / 2 - 55)
      .attr('y', dangerLineBounday - 10)
      .attr('transform', 'translate(87, -4)')
      .append('xhtml:input')
      .attr('type', 'text')
      .style('width', '25px')
      .style('height', '16px')
      .style('background-color', '#E50000')
      .style('color', 'white')
      .style('font-size', '10px')
      .style('line-height', '12px')
      .style('border-radius', '4px')
      .style('text-align', 'center')
      .style('display', 'none')
      .style('padding', '2px 0')
      .style('outline', 'none')
      .attr('value', this.capacity);

    const inputElement = this.capacityInput.node() as HTMLInputElement;
    const updateCapacity = () => {
      if (
        !isNaN(Number(inputElement.value)) &&
        Number(inputElement.value) !== this.capacity &&
        this.updateCapacityLimit
      ) {
        this.updateCapacityLimit(parseInt(inputElement.value));
      }
      this.capacityInput.style('background-color', '#E50000');
    };
    this.capacityInput.on('blur', () => updateCapacity());
    this.capacityInput.on('focus', () => inputElement.select());
    this.capacityInput.on('keyup', (event) => {
      if (event.key === 'Enter' && event.keyCode === 13) {
        updateCapacity();
      }
    });

    // Draw danger line boundary overlay
    this.svg
      .append('rect')
      .attr('fill', 'none')
      .style('pointer-events', 'all')
      .attr('x', margin.left)
      .attr('y', dangerLineBounday - 20)
      .attr('width', width - margin.right - margin.left)
      .attr('height', 40)
      .on('mouseout', () => {
        if (inputElement === document.activeElement) {
          return;
        }
        this.dangerLineBoundary.attr('stroke', '#F7796E');
        this.dangerLineBoundary.attr('stroke-width', 1);
        this.dangerLineLabel.style('display', 'none');
        this.capacityInput.style('display', 'none');
      })
      .on('mousemove', () => {
        this.dangerLineBoundary.attr('stroke', '#FF0000');
        this.dangerLineBoundary.attr('stroke-width', 2);
        this.dangerLineLabel.style('display', 'block');
        this.capacityInput.style('display', 'inline-block');
      })
      .on('click', () => {
        inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
        inputElement.focus();
        this.capacityInput.style('background-color', '#F7796E');
      });
  }

  /**
   * Listener mousemove event for overlay
   */
  onOverlayMouseMove = (event: MouseEvent) => {
    const { height } = this.dimensions;
    const { left, top } = this.container.getBoundingClientRect();
    const [offsetX, offsetY, d] = this.getMouseOffsets(event, this.data);

    if (!d || dayjs(d.stop).tz(this.tz) > this.timeRange.end) return;

    this.focus
      .selectAll('.d3-focus-line')
      .attr('pointer-events', 'none')
      .attr('transform', `translate(${offsetX},${height})`);
    this.focus
      .selectAll('.d3-focus-circle')
      .attr('pointer-events', 'none')
      .attr('transform', `translate(${offsetX},${offsetY})`);
    this.focus.style('display', 'block');

    const tooltipContent = (
      <>
        <span className="whitespace-nowrap text-xxs text-gray-400">
          {dateToLabel(
            convertTZ(dayjs(d.stop).toDate(), this.tz),
            new Intl.Locale(this.currentLang).language,
          )}
        </span>
        <span className="flex justify-start py-0.5 text-xs">
          {this.t('occupancy')}: {(d as TStatsOccupancyDP).value || 0}
        </span>
      </>
    );

    this.onMouseMove({ x: left + offsetX, y: top + offsetY }, tooltipContent); // Inverse transformation
  };

  /**
   * Update data
   */
  update({
    data,
    capacity,
    timeRange,
    t,
    currentLang,
    tz,
  }: {
    data: TStatsOccupancyDP[];
    capacity: number;
    timeRange: ITimeRange;
    t: TFunction<'translation', undefined>;
    currentLang: string;
    tz: string;
  }): void {
    this.data = data;
    this.capacity = capacity;
    this.timeRange = timeRange;
    this.t = t;
    this.tz = tz;
    this.currentLang = currentLang;
    this.svg?.remove();
    this.setAxesScale();
    this.draw();
  }
}
