import Check from '@/components/Icons/Check';
import {
  DropdownMenuButtonRoot,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
} from '@/components/primitives/DropdownMenu';
import { PopupContext } from '@/contexts';
import { useAuthUser } from '@/hooks';
import { useAppDispatch } from '@/redux/hooks';
import { setDialog } from '@/redux/reducers/dialog';
import { datadogRum } from '@datadog/browser-rum';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MoreIcon from '../Icons/MoreIcon';
import { Icon } from '../primitives/icon/Icon';
import { DeleteSite } from './DeleteSite';
import { EditSiteComponent } from './EditSite';

export function SiteEditMenu({ id, blockDelete }: { id: string; blockDelete: boolean }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setConfig: setPopupConfig } = useContext(PopupContext);

  const { isSuperUser: canDelete } = useAuthUser();

  const handleOpenDialog = () => {
    datadogRum.addAction('Buildings Details', {
      source: 'sites-and-buildings',
      modal: 'Site',
    });
    dispatch(
      setDialog({
        options: { className: 'rounded-[15px]' },
        content: (
          <EditSiteComponent
            onSave={() =>
              setPopupConfig?.({
                show: true,
                content: (
                  <Fragment>
                    <Check className="my-0.5 ml-3.5 mr-2 h-4 w-4 rounded-[10px] bg-green-500 p-0.5 text-white" />
                    <span className="mr-2">Saved successfully!</span>
                  </Fragment>
                ),
                timeoutOption: {
                  enable: true,
                  timeout: 3000,
                },
              })
            }
            siteId={id}
          />
        ),
        fixedWidth: 400,
      }),
    );
  };
  const openDeleteDialog = () => {
    datadogRum.addAction('Open delete site modal', { source: 'sites-and-buildings' });
    dispatch(
      setDialog({
        options: { className: 'rounded-[15px] w-[400px]' },
        content: (
          <DeleteSite
            id={id}
            blockDelete={blockDelete}
            onSave={() =>
              setPopupConfig?.({
                show: true,
                content: (
                  <Fragment>
                    <Check className="my-0.5 ml-3.5 mr-2 h-4 w-4 rounded-[10px] bg-green-500 p-0.5 text-white" />
                    <span className="mr-2">Deleted successfully!</span>
                  </Fragment>
                ),
                timeoutOption: {
                  enable: true,
                  timeout: 3000,
                },
              })
            }
          />
        ),
        fixedWidth: 400,
      }),
    );
  };

  return (
    <DropdownMenuRoot>
      <DropdownMenuButtonRoot
        className="hover:bg-gray-100 rounded-md"
        onClick={() => {
          datadogRum.addAction('Site menu opened', {
            source: 'sites-and-buildings',
          });
        }}
      >
        <MoreIcon className="my-2 mx-2 h-2 w-2 transform rotate-90" />
      </DropdownMenuButtonRoot>
      <DropdownMenuContent align="start" side="bottom">
        <DropdownMenuItem onClick={handleOpenDialog}>
          <Icon name="gear" size={14} className="hover:bg-gray-100" /> {t('details')}
        </DropdownMenuItem>
        {canDelete && (
          <DropdownMenuItem onClick={openDeleteDialog}>
            <Icon name="trash" size={14} className="hover:bg-gray-100" />
            {t('delete')}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
}
