import { useState, useCallback, useEffect, lazy, Suspense } from 'react';
import { useSRZId } from '@/hooks';
import { AutoTooltip, LoadingSpinner, SlideRightIcon } from '@/components';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserFeedbackIcon, CircleIcon } from '@/components/Icons';
import clsx from 'clsx';
import { SVRendererContextProvider } from '@/contexts';
import DetailsPanel from '@/components/DetailsPanel';
import { useGate } from 'statsig-react';
import { isDev } from '@/constants';
import {
  PageLayoutMainContent,
  PageLayoutNavBar,
  PageLayoutNavBarEnd,
  PageLayoutNavBarStart,
} from '@/components/PageLayout';
import { gql } from '@apollo/client';
import {
  useMainboardRoomsQuery,
  useMainboardSpaceQuery,
  useMainboardZonesQuery,
} from '@/.generated/graphql';
import { datadogRum } from '@datadog/browser-rum';
import toast from 'react-hot-toast';

// Todo: uncomment when changes are ready
// const SpaceVisualizer = lazy(() => import('@/components/SpaceVisualizer'));
const DeviceManagementPage = lazy(() => import('@/pages/DeviceManagementPage'));
const Dashboard = lazy(() => import('../Dashboard'));
const ScopingToolPage = lazy(() => import('@/pages/StudioPage'));
const DemoPage = lazy(() => import('@/pages/DemoPage'));

interface IMainboardProps {
  isRoom: boolean;
  isZone: boolean;
}

// FIXME: this component is a redundant abstraction with OrganizationPage,
// the only component which renders it. Pieces should be pulled out into
// sub-components and bundled up into OrganizationPage.
export const Mainboard = ({ isRoom, isZone }: IMainboardProps) => {
  const { search, pathname } = useLocation();
  const { spaceId, roomId, zoneId } = useSRZId();

  const {
    loading: spaceLoading,
    data: spaceData,
    error: spaceErrorData,
  } = useMainboardSpaceQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      spaceId: spaceId!,
    },
    skip: !spaceId,
  });
  const space = spaceData?.floors.data?.[0] ?? null;

  const {
    loading: roomLoading,
    data: roomData,
    error: roomErrorData,
  } = useMainboardRoomsQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      roomId: roomId!,
    },
    skip: !spaceId || !roomId,
  });
  const room = roomData?.rooms?.data?.[0] ?? null;

  const {
    loading: zoneLoading,
    data: zoneData,
    error: zoneErrorData,
  } = useMainboardZonesQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      zoneId: zoneId!,
    },
    skip: !spaceId || !zoneId,
  });
  const zone = zoneData?.zones?.data?.[0] ?? null;

  const { t } = useTranslation();
  const [detailPanelOpen, setDetailPanelOpen] = useState<boolean>(false);
  const { value, isLoading } = useGate('space_visualizer');

  // TODO Remove once space visualizer can be shown to all customers
  const showSpaceVisualizer = (value && !isLoading && !isZone) || isDev;

  // Toggle detail panel
  const toggleDetailPanel = useCallback(() => {
    datadogRum.addAction('Open Details Panel', { source: 'edit-details' });
    setDetailPanelOpen((val) => !val);
  }, []);

  // Whenever another space/room/zone or another tab is selected, close details panel
  useEffect(() => {
    setDetailPanelOpen(false);
  }, [spaceId, roomId, zoneId, pathname]);

  const loading = spaceLoading || roomLoading || zoneLoading;

  const renderError = () => {
    if (spaceErrorData) {
      if (spaceErrorData.graphQLErrors.length > 0) {
        return 'Failed to load space: ' + spaceErrorData.graphQLErrors[0].message;
      }
      if (spaceErrorData.message !== '') {
        return 'Failed to load space: ' + spaceErrorData.message;
      }
      return 'Failed to load space: Unknown error';
    }
    if (roomErrorData) {
      if (roomErrorData.graphQLErrors.length > 0) {
        return 'Failed to load room: ' + roomErrorData.graphQLErrors[0].message;
      }
      if (roomErrorData.message !== '') {
        return 'Failed to load room: ' + roomErrorData.message;
      }
      return 'Failed to load room: Unknown error';
    }
    if (zoneErrorData) {
      if (zoneErrorData.graphQLErrors.length > 0) {
        return 'Failed to load zone: ' + zoneErrorData.graphQLErrors[0].message;
      }
      if (zoneErrorData.message !== '') {
        return 'Failed to load zone: ' + zoneErrorData.message;
      }
      return 'Failed to load zone: Unknown error';
    }
    return 'Failed to load data';
  };

  const hasData = space || room || zone;
  const hasError = !!spaceErrorData || !!roomErrorData || !!zoneErrorData;
  // if there's data available, but there's an error, show a toast instead
  // of blocking the whole view. If there's no data, we render a full
  // error page below.
  const toastErrorMessage = hasError && hasData ? renderError() : null;
  useEffect(() => {
    if (toastErrorMessage) {
      toast.error(toastErrorMessage, {
        id: 'dashboard-error',
      });
    }
  }, [toastErrorMessage]);

  return (
    <div className={clsx('flex flex-grow flex-col min-h-0', loading && 'overflow-hidden')}>
      {loading ? (
        <LoadingSpinner />
      ) : !hasData && hasError ? (
        <div className="flex h-full w-full items-center justify-center text-xl">
          {renderError()}
        </div>
      ) : (
        <>
          <PageLayoutNavBar>
            <PageLayoutNavBarStart>
              <NavLink
                role="tab"
                aria-label="Charts tab"
                exact
                to={'/' + search}
                activeClassName="border-b-2 border-gray-900"
                className="flex h-full items-center text-gray-400"
              >
                {t('charts')}
              </NavLink>
              <NavLink
                to={'/studio' + search}
                activeClassName="border-b-2 border-gray-900"
                className="flex h-full items-center text-gray-400"
              >
                Studio
              </NavLink>
              <NavLink
                to={'/devices' + search}
                activeClassName="border-b-2 border-gray-900"
                className="flex h-full items-center text-gray-400"
              >
                {t('deviceManagement.title')}
              </NavLink>
              {/* TODO Disabling space visualizer for zone temporarily */}
              {showSpaceVisualizer && (
                <NavLink
                  to={'/space-visualizer' + search}
                  activeClassName="border-b-2 border-gray-900"
                  className="h-full items-center text-gray-400 flex flex-row"
                >
                  <span>{t('spaceVisualizer')}</span>
                  <span className="ml-2 border rounded px-1 text-xxxs font-semibold leading-4">
                    Experimental Feature
                  </span>
                </NavLink>
              )}
              {isDev && (
                <NavLink
                  to={'/demo' + search}
                  activeClassName="border-b-2 border-gray-900"
                  className="flex h-full items-center text-gray-400"
                >
                  Demo
                </NavLink>
              )}
            </PageLayoutNavBarStart>
            <Route path={['/', '/studio']} exact>
              <PageLayoutNavBarEnd>
                <button
                  data-id="showHideDetails"
                  type="button"
                  className="flex cursor-pointer flex-row items-center gap-2 text-sm text-gray-600"
                  onClick={toggleDetailPanel}
                >
                  {detailPanelOpen
                    ? isZone
                      ? t('hideZoneDetails')
                      : isRoom
                      ? t('hideRoomDetails')
                      : t('hideSpaceDetails')
                    : isZone
                    ? t('showZoneDetails')
                    : isRoom
                    ? t('showRoomDetails')
                    : t('showSpaceDetails')}
                  {detailPanelOpen && (
                    <SlideRightIcon className="h-3 w-3 fill-current text-gray-400" />
                  )}
                </button>
              </PageLayoutNavBarEnd>
            </Route>
          </PageLayoutNavBar>
          <PageLayoutMainContent>
            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                {showSpaceVisualizer && (
                  <Route path="/space-visualizer">
                    <SVRendererContextProvider>
                      <div className="relative h-full flex-grow overflow-y-auto overflow-x-hidden bg-gray-50 px-8 py-10">
                        <div className="flex items-center justify-center flex-col h-full pb-8">
                          <CircleIcon />
                          <div className="w-[532px] mt-3 text-xl flex text-center justify-center flex-col">
                            <div className="font-bold justify-center mb-3">
                              {t('featureUnavailable')}
                            </div>
                            <div className="font-normal justify-center">
                              {t('featureUnavailableDescription')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SVRendererContextProvider>
                  </Route>
                )}
                <Route path="/studio">
                  <ScopingToolPage />
                </Route>

                <Route path="/devices">
                  <DeviceManagementPage search={zone?.name || room?.name || space?.name} />
                </Route>
                <Route path="/demo">
                  <DemoPage />
                </Route>
                <Route path="/">
                  {spaceId && (
                    <Dashboard
                      // Force a remount when we change space/room/zone. This helps prevent caching of data across routes.
                      // At some point, we should probably use a router that supports this natively.
                      key={spaceId + roomId + zoneId}
                      isRoom={isRoom}
                      isZone={isZone}
                      spaceId={spaceId}
                    />
                  )}
                </Route>
              </Switch>
              {detailPanelOpen && (
                <DetailsPanel isRoom={isRoom} isZone={isZone} isStudio={pathname === '/studio'} />
              )}
            </Suspense>
          </PageLayoutMainContent>
        </>
      )}
      <AutoTooltip
        label="Share your feedback"
        className="absolute right-5 bottom-5"
        placement="bottom-end"
      >
        <a
          href="https://support.butlr.io/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer"
          aria-label="User feedback"
        >
          <UserFeedbackIcon className="text-gray-900 hover:text-gray-700" />
        </a>
      </AutoTooltip>
    </div>
  );
};

Mainboard.queries = {
  Space: gql`
    query MainboardSpace($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          name
        }
      }
    }
  `,
  Room: gql`
    query MainboardRooms($roomId: String!) {
      rooms(ids: [$roomId]) {
        data {
          id: room_id
          name
        }
      }
    }
  `,
  Zone: gql`
    query MainboardZones($zoneId: String!) {
      zones(ids: [$zoneId]) {
        data {
          id: zone_id
          name
        }
      }
    }
  `,
};
