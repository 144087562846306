export const CHART_QUICK_RANGE_BUTTON_LABELS = [
  'today',
  'last5Mins',
  'thisWeek',
  'thisMonth',
  'thisYear',
] as const;
export const DETECTION_QUICK_RANGE_BUTTON_LABELS = ['today', 'last5Mins', 'thisWeek'] as const;

/**
 * Date range picker behaves differently for
 * different scenarios - mainly which quick selection
 * options it surfaces.
 */
export enum EDateRangePickerType {
  CHARTS = 'charts',
  DETECTION = 'detection',
}
