import { useCallback, useState } from 'react';
import { useInterval } from '@/hooks';
/**
 * Hooks for updating as runtime
 * @param interval interval in ms to refresh the date
 * @returns date object
 */
export const useCurrentDate = (interval = 1000): Date => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const refresh = useCallback(() => {
    setCurrentDate(new Date());
  }, []);

  useInterval(interval, refresh, []);

  return currentDate;
};
