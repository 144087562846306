import React from 'react';
import { useThirdParty } from '@/hooks';
import { FONT_FAMILY_PER_LANG } from './constants';
import { ELanguage } from '@/types';
import { useTranslation } from 'react-i18next';
import Routing from 'Routing';
import { Toaster } from '@/components/primitives/Toaster';

import './pendo';
import './datadog';

function App() {
  const {
    i18n: { language: currentLang },
  } = useTranslation();
  useThirdParty();

  return (
    <div
      id="app-root"
      className={'relative h-full w-full overflow-hidden font-normal'}
      style={{ fontFamily: `${FONT_FAMILY_PER_LANG[currentLang as ELanguage]}` }}
    >
      <Routing />
      <Toaster />
    </div>
  );
}

export default App;
