import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26669 1.5199H9.73336C10.1457 1.5199 10.48 1.8542 10.48 2.26657V4.13324H5.25336H4.13336V5.25324V7.6799V8.7999H5.25336H6.74669H7.86669V9.9199V10.4799H2.26669C1.85432 10.4799 1.52002 10.1456 1.52002 9.73324V2.26657C1.52002 1.8542 1.85432 1.5199 2.26669 1.5199ZM8.98669 10.4799H9.73336C10.1457 10.4799 10.48 10.1456 10.48 9.73324V5.25324H5.25336V7.6799H7.86669H8.98669V8.7999V10.4799ZM7.86669 11.5999H2.26669C1.23576 11.5999 0.400024 10.7642 0.400024 9.73324V2.26657C0.400024 1.23564 1.23576 0.399902 2.26669 0.399902H9.73336C10.7643 0.399902 11.6 1.23564 11.6 2.26657V4.13324V5.25324V9.73324C11.6 10.7642 10.7643 11.5999 9.73336 11.5999H8.98669H7.86669Z"
      />
    </svg>
  );
};

export default SvgComponent;
