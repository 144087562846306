import { PageLayoutSidebar, PageLayoutSidebarLogoContainer } from '../PageLayout';
import OrganizationLogo from '../OrganizationLogo';
import { SidebarSpaceHierarchy } from './SidebarSpaceHierarchy';
import { useTranslation } from 'react-i18next';
import { withClassName } from '@/hocs/withClassName';

export function Sidebar() {
  const { t } = useTranslation();

  return (
    <PageLayoutSidebar className="text-xs text-gray-800">
      <PageLayoutSidebarLogoContainer>
        <OrganizationLogo />
      </PageLayoutSidebarLogoContainer>
      <SidebarSpaceHierarchy />
      <SidebarLink
        className="mt-auto"
        href="https://docs.butlr.io"
        target="_blank"
        rel="noreferrer"
      >
        {t('apiDoc')}
      </SidebarLink>
    </PageLayoutSidebar>
  );
}

const SidebarLink = withClassName(
  'a',
  'outline-none flex h-12 w-full flex-row items-center justify-between px-5 font-semibold capitalize text-lg text-black border-gray-200 border-t',
  'hover:text-gray-900',
);
