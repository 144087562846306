import { useImpersonateClient } from '@/api/impersonate';
import { LoadingSpinner, SearchInput } from '@/components';
import { PageLayoutMain, PageLayoutMainContent, PageLayoutRoot } from '@/components/PageLayout';
import { useAuthUser } from '@/hooks';
import { Header } from '@/pages/HomePage/OrganizationPage/Header';
import { Sidebar } from '@/pages/HomePage/OrganizationPage/Sidebar';
import { useAppDispatch, useSuperUser } from '@/redux/hooks';
import { setIsSuperUserDashboard } from '@/redux/reducers/superuser';
import { ECommonVariants } from '@/types';
import { ChevronRightIcon } from '@heroicons/react/solid';
import {
  ChangeEvent,
  FC,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const SuperUserPage: FC = memo(() => {
  const { selectedClient } = useSuperUser();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user, clientId } = useAuthUser();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const initialLoadingRef = useRef(true);

  // Extract name and subclients from the selected client
  const [name, subClients] = useMemo(() => {
    if (!selectedClient) return ['', []];

    return Object.entries(selectedClient)[0];
  }, [selectedClient]);

  // Filtered sub clients
  const filteredSubClients = useMemo(() => {
    let s;

    if (searchValue) {
      s = Object.entries(subClients).filter(([, subClientData]) =>
        subClientData.emails.toString().toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      s = Object.entries(subClients);
    }

    return s.sort((a, b) => {
      const aEmail = a[1].emails.filter((email) => email !== user?.email)[0];
      const bEmail = b[1].emails.filter((email) => email !== user?.email)[0];

      return aEmail > bEmail ? 1 : aEmail < bEmail ? -1 : 0;
    });
  }, [searchValue, subClients, user]);

  // Handler for changing search input
  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  // Handler for clearing search input
  const handleSearchClear = useCallback(() => {
    setSearchValue('');
  }, []);

  // Handler for opening sub client dashboard
  const impersonate = useImpersonateClient();
  const handleSubClientClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      setLoading(true);
      const subClientId = e.currentTarget.value;
      try {
        await impersonate(subClientId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to switch organizations', err);
        toast.error('Failed to switch organizations');
        setLoading(false);
      }
    },
    [impersonate],
  );

  useEffect(() => {
    if (!clientId) return;

    setLoading(false);

    if (initialLoadingRef.current) {
      initialLoadingRef.current = false;
      return;
    }

    dispatch(setIsSuperUserDashboard(false));
  }, [dispatch, clientId]);

  return (
    <PageLayoutRoot>
      <Sidebar />
      <PageLayoutMain>
        <Header />
        <PageLayoutMainContent className="bg-gray-50 py-3 px-5 flex-col">
          {loading ? <LoadingSpinner /> : null}
          {selectedClient && !loading ? (
            <>
              <div className="mb-4 flex w-full items-center justify-between px-4">
                <p className="text-xl font-semibold capitalize text-gray-900">{name}</p>
                <SearchInput
                  className="max-h-96 flex-grow-0"
                  variant={ECommonVariants.SECONDARY}
                  value={searchValue}
                  onChange={handleSearchChange}
                  onClear={handleSearchClear}
                  minimized
                />
              </div>
              <div className="mb-2 flex w-full flex-col rounded-2xl border border-gray-100 bg-white">
                <p className="h-12 px-10 py-4 text-sm text-gray-500">
                  {t('sites')}/{t('kits')}
                </p>
                {filteredSubClients.map(([subClientId, subClientData], idx) => {
                  return (
                    <button
                      data-id="subClientButton"
                      key={`${subClientId}-${idx}`}
                      className="flex h-14 cursor-pointer items-center justify-between border-t border-gray-100 px-10 py-5 capitalize hover:bg-gray-100"
                      value={subClientId}
                      onClick={handleSubClientClick}
                    >
                      <p>
                        {subClientData.emails
                          .filter((email) => email !== user?.email)
                          .map((email) => email.substring(0, email.indexOf('@')))
                          .join(', ')}
                      </p>
                      <ChevronRightIcon className="h-5 w-5 text-gray-300" />
                    </button>
                  );
                })}
              </div>
            </>
          ) : null}
        </PageLayoutMainContent>
      </PageLayoutMain>
    </PageLayoutRoot>
  );
});
