import {
  ZonedDateTime,
  startOfMonth,
  isSameDay,
  startOfYear,
  startOfWeek,
} from '@internationalized/date';
import { DateRange } from '@react-types/datepicker';
import { TIME_RANGE_MODE } from '@/redux/reducers/time';

export function isQuickRangeEdited(
  rangeValue: DateRange,
  nowTime: ZonedDateTime,
  timeRangeMode: TIME_RANGE_MODE,
  currentLang: string,
): boolean {
  const rangeStart = rangeValue.start as ZonedDateTime;
  const rangeEnd = rangeValue.end as ZonedDateTime;
  if (!rangeStart || !rangeEnd) return false;
  const nowDate = nowTime.set({ hour: 0, minute: 0 });
  const startDayOfWeek = startOfWeek(nowDate, currentLang);
  if (!isSameDay(rangeEnd, nowDate)) return true;
  switch (timeRangeMode) {
    case TIME_RANGE_MODE.TODAY:
      return rangeStart.compare(nowDate) !== 0;
    case TIME_RANGE_MODE.THIS_WEEK:
      return (
        rangeStart.compare(
          startDayOfWeek.day === nowDate.day
            ? startDayOfWeek.subtract({ days: 6 })
            : startDayOfWeek.add({ days: 1 }),
        ) !== 0
      );
    case TIME_RANGE_MODE.LAST_5_MINS:
      // Tolerate 1 min's difference, 60 * 1000ms
      return rangeStart.compare(nowTime.subtract({ minutes: 5 })) < -60 * 1000;
    case TIME_RANGE_MODE.THIS_MONTH:
      return rangeStart.compare(startOfMonth(nowDate)) !== 0;
    case TIME_RANGE_MODE.THIS_YEAR:
      return rangeStart.compare(startOfYear(nowDate)) !== 0;
  }
  return false;
}

export function getNewDateRange(
  nowTime: ZonedDateTime,
  timeRangeMode: TIME_RANGE_MODE,
  currentLang: string,
): DateRange {
  let start = nowTime.copy().set({ hour: 0, minute: 0 });
  const end = nowTime.copy();
  switch (timeRangeMode) {
    case TIME_RANGE_MODE.THIS_WEEK:
      start = startOfWeek(nowTime, currentLang);
      if (start.day === nowTime.day) {
        start = start.subtract({ days: 7 });
      }
      start = start.set({ hour: 0, minute: 0 }).add({ days: 1 });
      break;
    case TIME_RANGE_MODE.LAST_5_MINS:
      start = nowTime.subtract({ minutes: 5 });
      break;
    case TIME_RANGE_MODE.THIS_MONTH:
      start = startOfMonth(end).set({ hour: 0, minute: 0 });
      break;
    case TIME_RANGE_MODE.THIS_YEAR:
      start = startOfYear(end).set({ hour: 0, minute: 0 });
      break;
  }
  return { start, end };
}
