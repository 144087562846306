import { memo, useEffect } from 'react';
import { useSRZId } from '@/hooks';
import { Mainboard } from '@/pages/HomePage/OrganizationPage/Mainboard';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Route, useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { PopupContextProvider } from '@/contexts';
import { PageLayoutMain, PageLayoutRoot } from '@/components/PageLayout';
import { selectDirty } from '@/components/studio/ScopingEditor/reducer';
import { gql } from '@apollo/client';
import { useOrganizationPageFloorsLazyQuery } from '@/.generated/graphql';
import { useSelector } from 'react-redux';

export const OrganizationPage = () => {
  const { spaceId, roomId, zoneId } = useSRZId();
  const history = useHistory();
  const [getFloors] = useOrganizationPageFloorsLazyQuery();

  const isDirty = useSelector(selectDirty);

  useEffect(() => {
    const onbeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (isDirty) {
      window.addEventListener('beforeunload', onbeforeunload);
    }

    return () => window.removeEventListener('beforeunload', onbeforeunload);
  }, [isDirty]);

  // Error handling, and redirect to first space if no space is indicated by query param
  useEffect(() => {
    if (!spaceId) {
      // Space id is not defined in url, redirect to first floor
      getFloors().then((res) => {
        const floors = res.data?.floors?.data ?? [];
        const floor = sortBy(floors, 'name')[0]?.id;
        if (floor) {
          history.push({ search: `?spaceId=${floor}` });
        }
      });
    }
  }, [spaceId, history, getFloors]);

  return (
    <PopupContextProvider>
      <PageLayoutRoot>
        <Route exact path={['/', '/space-visualizer', '/demo']}>
          <Sidebar />
        </Route>
        <PageLayoutMain>
          <Header />
          {(spaceId || roomId || zoneId) && <Mainboard isRoom={!!roomId} isZone={!!zoneId} />}
        </PageLayoutMain>
      </PageLayoutRoot>
    </PopupContextProvider>
  );
};

OrganizationPage.queries = {
  Floors: gql`
    query OrganizationPageFloors {
      floors {
        data {
          id: floor_id
          name
        }
      }
    }
  `,
};

export default memo(OrganizationPage);
