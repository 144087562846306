import { useEffect, useState, useContext, Fragment } from 'react';
import { PopupContext } from '@/contexts';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Copy from '@/components/Icons/Copy';
import Check from '@/components/Icons/Check';
import { ICommonComponentProps } from '@/types';

interface ICopyButtonProps extends ICommonComponentProps {
  text: string;
}

export default function CopyButton({ text, className, children }: ICopyButtonProps) {
  const { t } = useTranslation();
  const { setConfig } = useContext(PopupContext);
  const [hasCopied, setHasCopied] = useState(false);
  console.log(text);
  useEffect(() => {
    setConfig?.({
      show: hasCopied,
      content: (
        <Fragment>
          <Check className="my-0.5 ml-3.5 mr-2 h-4 w-4 rounded-[10px] bg-green-500 p-0.5 text-white" />
          {t('copiedSuccessfully')}
        </Fragment>
      ),
      timeoutOption: {
        enable: true,
        timeout: 3000,
      },
    });
    const timeout = setTimeout(() => setHasCopied(false), 3000);
    return () => clearTimeout(timeout);
  }, [hasCopied]);

  const onCopy = () => {
    navigator.clipboard.writeText(text);
    setHasCopied(true);
  };

  return (
    <button
      aria-label="Copy Button"
      className={clsx('copy-button', className, hasCopied && 'pointer-events-none')}
      onClick={onCopy}
      disabled={hasCopied}
    >
      <Copy className="my-0.5" />
      {children}
    </button>
  );
}
