import { PlusIcon } from '@/components/Icons';
import { Input } from '@/components/primitives/Input';
import { SubmitButton } from '@/components/primitives/SubmitButton';
import { useAppDispatch } from '@/redux/hooks';
import { gql, useMutation } from '@apollo/client';
import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { GET_SPACE_STATE } from '../../../../components/studio/ScopingEditor/useGetState';
import { useSRZId } from '@/hooks/useOrg';
import { datadogRum } from '@datadog/browser-rum';
import { baseApi } from '@/hooks/api/base';
import { AutoTooltip } from '@/components/primitives/AutoTooltip';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { SidebarFloorDocument } from '@/.generated/graphql';

const CREATE_SPACE_MUTATION = gql`
  mutation createFloors($floors: [CreateFloorInput!]!) {
    createFloors(floors: $floors) {
      client_id
      id: floor_id
      name
      capacity {
        mid
        max
      }
      installation_date
    }
  }
`;

function ImportantNotice() {
  const dispatch = useAppDispatch();
  const [dontShow, setDontShowAgain] = useState(false);
  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-xl font-semibold text-product-gray900">Important notice</h1>
      <p className="text-xs text-product-gray900">
        Your adjustments will <b>NOT</b> affect the Charts on the dashboard until you click on the
        &apos;Save&apos; button.
      </p>
      <label className="flex gap-2 text-xs text-product-gray600">
        <input
          type="checkbox"
          value=""
          checked={dontShow}
          onChange={() => setDontShowAgain((c) => !c)}
        />
        Do not show this message again
      </label>
      <div className="flex justify-end gap-4">
        <button
          className="flex justify-center rounded-md bg-product-gray900 px-5 py-2 align-middle text-white hover:bg-product-gray700 active:bg-product-gray800"
          onClick={() => {
            if (dontShow) {
              window.localStorage.setItem('studioImportantNotice', 'true');
            } else {
              window.localStorage.removeItem('studioImportantNotice');
            }
            dispatch(closeDialog());
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
}

export function CreateSpaceDialog() {
  const { spaceId } = useSRZId();
  const dispatch = useAppDispatch();
  const [createSpace] = useMutation(CREATE_SPACE_MUTATION, {
    refetchQueries: [GET_SPACE_STATE, SidebarFloorDocument],
    variables: {
      spaceId,
    },
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: { name: '' },
  });
  const history = useHistory();
  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(async ({ name }) => {
        setLoading(true);
        // leaving "space" action here for historical comparison, but transitioning to
        // new naming convention for actions
        datadogRum.addAction('Create space', { source: 'studio-on-web' });
        datadogRum.addAction('Create floor', { source: 'studio-on-web' });
        const { data } = await createSpace({
          variables: {
            floors: [
              {
                name,
              },
            ],
          },
        });
        dispatch(baseApi.util.invalidateTags([{ type: 'Space', id: spaceId }, 'Space']));
        const id = data?.createFloors?.[0]?.id;
        if (id) {
          history.push({ pathname: '/studio', search: `?spaceId=${id}` });
        }
        setLoading(false);
        if (!window.localStorage.getItem('studioImportantNotice')) {
          dispatch(
            setDialog({
              content: <ImportantNotice />,
            }),
          );
        } else {
          dispatch(closeDialog());
        }
      })}
    >
      <h1 className="text-xl">Add a new floor</h1>
      <Input
        {...register('name', { required: true })}
        placeholder="Please enter a floor name"
        label="Name"
      />

      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded-md px-4 py-1 text-sm text-product-gray900 hover:bg-product-gray50"
          onClick={() => dispatch(closeDialog())}
        >
          Cancel
        </button>
        <SubmitButton loading={loading} loadingContent="Creating" disabled={!isValid || !isDirty}>
          Create floor
        </SubmitButton>
      </div>
    </form>
  );
}

export function CreateSpaceButton() {
  const dispatch = useAppDispatch();
  const onClick = async () =>
    dispatch(
      setDialog({
        content: <CreateSpaceDialog />,
      }),
    );
  return (
    <AutoTooltip label="Create Floor">
      <button
        className={clsx(
          'whitespace-nowrap rounded-full bg-product-gray100 w-[36px] h-[36px] flex items-center justify-center text-product-gray900 hover:bg-product-gray50',
        )}
        onClick={onClick}
      >
        <PlusIcon className="w-4" color="black" />
      </button>
    </AutoTooltip>
  );
}
