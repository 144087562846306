import { UserMenuFloorFragment } from '@/.generated/graphql';
import { useAuthLogout, useAuthUser, useCurrentDate } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import { convertDateByTZ } from '@/utils';
import { gql } from '@apollo/client';
import { User } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useCallback, useState } from 'react';
import { useAppDispatch } from '@/redux/hooks';
import { useTranslation } from 'react-i18next';
import { UserSettings } from './UserSettings';
import { isAppleOS } from '@/utils/platform';
import { useHotkeys } from 'react-hotkeys-hook';
import { NewOrgSwitcher } from '@/pages/HomePage/OrganizationPage/Header/UserMenu/NewOrgSwitcher';
import { setDialog } from '@/redux/reducers/dialog';

interface IUserMenuProps extends ICommonComponentProps {
  user: User;
  space: UserMenuFloorFragment | null;
}

export const UserMenu = ({ className, style, space, ...rest }: IUserMenuProps) => {
  const dispatch = useAppDispatch();
  const {
    t,
    i18n: { language: currentLang },
  } = useTranslation();
  const { isSuperUser } = useAuthUser();
  const triggerLogout = useAuthLogout();
  const currentDate = useCurrentDate();

  // Handler for opening settings dialog
  const handleOpenSettings = useCallback(() => {
    dispatch(setDialog({ content: <UserSettings /> }));
  }, [dispatch]);

  const [newOrgSwitcherVisible, setNewOrgSwitcherVisible] = useState(false);
  const showNewOrgSwitcher = () => setNewOrgSwitcherVisible(true);

  useHotkeys('meta+k', () => {
    showNewOrgSwitcher();
  });

  return (
    <>
      <Menu
        as="div"
        className={clsx('relative z-50 inline-block text-left', className)}
        style={style}
        {...rest}
      >
        <Menu.Button
          data-id="userMenuButton"
          className="flex flex-row items-center justify-end rounded-full border border-gray-100 p-2 text-sm hover:bg-gray-100"
        >
          <span className="sr-only">Open user menu</span>
          <div className="flex h-8 items-center px-2">
            <span className="text-md">
              {convertDateByTZ(
                currentDate,
                space?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
                currentLang,
                t,
              )}
            </span>
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    data-id="userSettingsButton"
                    className={clsx(
                      active ? 'bg-gray-100' : 'bg-white',
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                    )}
                    onClick={handleOpenSettings}
                  >
                    {t('settings')}
                  </button>
                )}
              </Menu.Item>
              {isSuperUser && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      data-id="switchOrganizationButton"
                      className={clsx(
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2',
                        active ? 'bg-gray-100' : 'bg-white',
                      )}
                      onClick={showNewOrgSwitcher}
                    >
                      <span>{t('switchOrganization')}</span>
                      <kbd className="place-self-end text-xs bg-gray-50 py-[2px] px-2 border border-gray-200 border-solid rounded-lg tracking-tighter">
                        {isAppleOS() ? '⌘' : 'Ctrl'} + K
                      </kbd>
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    data-id="logoutButton"
                    className={clsx(
                      'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      active ? 'bg-gray-100' : 'bg-white',
                    )}
                    onClick={triggerLogout}
                  >
                    {t('logOut')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {isSuperUser && (
        <NewOrgSwitcher open={newOrgSwitcherVisible} onOpenChange={setNewOrgSwitcherVisible} />
      )}
    </>
  );
};

UserMenu.fragments = {
  Floor: gql`
    fragment UserMenuFloor on Floor {
      id: floor_id
      timezone
    }
  `,
};
