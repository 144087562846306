import { withClassName } from '@/hocs/withClassName';
import { Toggle } from '@radix-ui/react-toggle';
import {
  Children,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { IconButton } from '../primitives';
import { Icon } from '../primitives/icon/Icon';
import clsx from 'clsx';
import { FilterContext } from './SidebarSpaceHierarchy';

const SidebarItemRoot = withClassName(
  'div',
  'flex flex-row  p-0 items-center opacity-1 h-[--content-height] rounded-r-full',
  'transition-all duration-300 linear overflow-x-hidden overflow-y-clip',
  'data-[open=false]:h-[0px] data-[open=false]:py-0 data-[open=false]:opacity-0',
  'data-[selected=true]:text-white data-[selected=true]:bg-black data-[selected=true]:hover:bg-none',
);

const SidebarItemToggle = withClassName(
  Toggle,
  'flex flex-row items-center py-2 px-1 pr-4 w-full text-inherit overflow-hidden text-left min-w-80px',
);

const SidebarItemLabel = withClassName(
  'span',
  'flex flex-row items-center gap-2 px-1 rounded-md data-[selected=true]:text-white',
);

const SidebarGroupingChildren = withClassName('div', 'w-full');

const SidebarGroupSection = withClassName('div', 'overflow-hidden w-full flex-shrink-0');

/** Tells whether the parent is open or not */
export const SidebarGroupingContext = createContext<{
  parentOpen: boolean;
  level: number;
  searchActive: boolean;
}>({
  parentOpen: true,
  level: -1,
  searchActive: false,
});

export const SidebarSection = ({
  children,
  className,
  onSelectedChange,
  selected,
  title,
  alwaysVisible,
  highlighted,
  selectable,
  toggleable = true,
  childSelected = false,
  childHighlighted = false,
  actionContent,
}: {
  children?: ReactNode;
  className?: string;
  onSelectedChange?: (selected: boolean) => void;
  selected?: boolean;
  title: ReactNode;
  alwaysVisible?: boolean;
  highlighted?: boolean;
  selectable?: boolean;
  toggleable?: boolean;
  childSelected?: boolean;
  childHighlighted?: boolean;
  actionContent?: ReactNode;
}) => {
  const [internalChildrenOpen, setChildrenOpen] = useState(!!selected);
  const { parentOpen, level } = useContext(SidebarGroupingContext);
  const { isSearchActive, filterValue } = useContext(FilterContext);
  let visible: boolean;
  if (isSearchActive && filterValue !== '') {
    visible = highlighted || childHighlighted;
  } else {
    visible = parentOpen || alwaysVisible || selected || highlighted || childSelected;
  }
  const toggleChildrenOpen = () => setChildrenOpen((prev) => !prev);
  const onSelect = (value: boolean) => {
    onSelectedChange?.(value);
  };

  useEffect(() => {
    // open an element if one of it's children are selected or visible, unless search is active and a filter is applied
    setChildrenOpen((!isSearchActive || !filterValue) && (selected || !!childSelected));
    //if an item is not visible, close all children as well
    if (!visible) {
      setChildrenOpen(false);
    }
  }, [selected, childSelected, isSearchActive, filterValue, visible]);

  const hasChildren = Children.count(children) > 0;
  const { rootRef, contentRef } = useSyncContentHeight();

  return (
    <SidebarGroupSection className={className}>
      <SidebarItemRoot
        data-open={visible}
        data-selected={selected}
        data-level={level}
        style={{ paddingLeft: 12 + level * 16 }}
        ref={rootRef}
        className={selectable !== false ? 'hover:bg-gray-100' : ''}
      >
        {toggleable && (
          <IconButton
            size="sm"
            className={clsx(
              'text-inherit bg-transparent hover:bg-transparent border-none text-product-gray500 hover:text-product-gray500',
              alwaysVisible
                ? 'opacity-100 transition-opacity'
                : 'opacity-0 group-hover:opacity-100 transition-opacity',
              {
                invisible: !hasChildren,
              },
            )}
            variant={selected ? 'solid' : 'ghost'}
            onClick={toggleChildrenOpen}
          >
            <Icon
              name="solid-down"
              size={12}
              className={clsx('transition-transform', {
                '-rotate-90': !internalChildrenOpen,
              })}
            />
          </IconButton>
        )}
        <SidebarItemToggle
          pressed={selected}
          onPressedChange={onSelect}
          ref={contentRef}
          className="flex justify-between items-center"
        >
          <SidebarItemLabel
            data-highlighted={highlighted}
            onClick={selectable === false ? toggleChildrenOpen : undefined}
          >
            {title}
          </SidebarItemLabel>
          {actionContent}
        </SidebarItemToggle>
      </SidebarItemRoot>
      <SidebarGroupingContext.Provider
        value={{ parentOpen: internalChildrenOpen, level: level + 1, searchActive: isSearchActive }}
      >
        <SidebarGroupingChildren>{children}</SidebarGroupingChildren>
      </SidebarGroupingContext.Provider>
    </SidebarGroupSection>
  );
};

/**
 * Watches height of content element and sets it as a css variable on the root element for
 * use in animations
 */
function useSyncContentHeight() {
  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    function sync() {
      if (rootRef.current && contentRef.current) {
        rootRef.current.style.setProperty(
          '--content-height',
          `${contentRef.current.clientHeight}px`,
        );
      }
    }

    sync();

    if (contentRef.current) {
      const observer = new ResizeObserver(sync);
      observer.observe(contentRef.current);
      return () => observer.disconnect();
    }
  }, [rootRef, contentRef]);

  return { rootRef, contentRef };
}
