import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.37 10c.165 0 .318-.07.454-.22l3.732-4.248a.774.774 0 00.204-.47V9.31c0 .4.266.69.617.69.357 0 .623-.29.623-.69V.696c0-.4-.266-.696-.623-.696-.351 0-.617.297-.617.696V4.94a.808.808 0 00-.204-.47L6.824.218A.605.605 0 006.371 0c-.357 0-.617.29-.617.683 0 .207.073.38.187.51l1.274 1.47 1.642 1.676-1.223-.058H.64c-.38 0-.64.297-.64.722 0 .42.26.716.64.716h6.994l1.223-.052-1.642 1.67-1.274 1.47a.761.761 0 00-.187.51c0 .393.26.683.617.683z" />
    </svg>
  );
};

export default SvgComponent;
