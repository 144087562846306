import React, { Fragment, FunctionComponent, memo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlineIcon, MessageIcon, AutoTooltip } from '@/components';
import { ICommonComponentProps } from '@/types';
import { COLORS } from '@/constants';
import clsx from 'clsx';

interface IQuestionMenuProps extends ICommonComponentProps {}

export const QuestionMenu: FunctionComponent<IQuestionMenuProps> = memo(
  ({ className, style, ...rest }) => {
    const { t } = useTranslation();

    return (
      <Menu
        as="div"
        className={clsx('relative z-30 inline-block text-left', className)}
        style={style}
        {...rest}
      >
        {({ open }) => (
          <>
            <AutoTooltip disabled={open} disableOnFocus label={t('helpCenter')}>
              <Menu.Button
                data-id="supportMenuButton"
                className="border-full flex flex-row items-center justify-end p-2 text-sm hover:bg-gray-100"
              >
                <span className="sr-only">Open question menu</span>
                <QuestionCircleOutlineIcon
                  width={22}
                  fill={open ? COLORS.red500 : COLORS.gray800}
                />
              </Menu.Button>
            </AutoTooltip>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://support.butlr.io"
                        data-id="learningCenterButton"
                        className={clsx(
                          'group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm',
                          active && 'bg-violet-500 bg-gray-100',
                          !active && 'bg-white',
                        )}
                      >
                        <QuestionCircleOutlineIcon width={22} /> {t('learningCenter')}
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://support.butlr.io/tickets"
                        data-id="contactSupportButton"
                        className={clsx(
                          'group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm',
                          active && 'bg-violet-500 bg-gray-100',
                          !active && 'bg-white',
                        )}
                      >
                        <MessageIcon width={22} />
                        {t('contactSupport')}
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  },
);
