import { useSpaceSwitcherGetFloorsQuery } from '@/.generated/graphql';
import { AutoTooltip, SpaceIcon } from '@/components';
import {
  SelectButton,
  SelectContent,
  SelectItemIndicator,
  SelectItemRoot,
  SelectItemText,
  SelectRoot,
} from '@/components/primitives/Select/Select';
import { gql } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface ISpaceSwitcherProps {
  value: string | undefined;
}

export const SpaceSwitcher = ({ value: currentSpaceId }: ISpaceSwitcherProps) => {
  const [open, setOpen] = useState(false);
  const { data, loading } = useSpaceSwitcherGetFloorsQuery();
  const url = useLocation();

  const spaces = useMemo(() => {
    const unorderedSpaces = data?.floors?.data ?? [];
    return [...unorderedSpaces].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const history = useHistory();
  const goToSpace = useCallback(
    (spaceId: string) => {
      history.push(`${url.pathname}?spaceId=${spaceId}`);
    },
    [history, url],
  );

  if (loading) return null;

  return (
    <SelectRoot open={open} onOpenChange={setOpen} value={currentSpaceId} onValueChange={goToSpace}>
      <AutoTooltip disabled={open} disableOnFocus label="Change floor">
        <SelectButton
          className="border-full flex flex-row items-center justify-end rounded-md py-2 px-4 bg-transparent hover:bg-gray-100 text-3xl font-semibold"
          data-id="space-switcher-button"
        />
      </AutoTooltip>
      <SelectContent>
        {spaces?.map((space) => (
          // These items require more customization because there's an icon at the beginning
          // which needs to not be included in the item's text
          <SelectItemRoot value={space.id} key={space.id}>
            <SpaceIcon className="fill-current" />
            <SelectItemText>{space.name || space.id}</SelectItemText>
            <SelectItemIndicator />
          </SelectItemRoot>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

SpaceSwitcher.fragments = {
  Floor: gql`
    fragment SpaceSwitcherFloor on Floor {
      id: floor_id
      name
    }
  `,
};

SpaceSwitcher.queries = {
  GetFloors: gql`
    query SpaceSwitcherGetFloors {
      floors {
        data {
          id: floor_id
          ...SpaceSwitcherFloor
        }
      }
    }
    ${SpaceSwitcher.fragments.Floor}
  `,
};
