import React, { FC, SVGProps } from 'react';

/** @deprecated use <Icon name="..." /> (import from @/components/primitives/icons/Icon) instead. */
const SvgComponent: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="message-outline"
      className="prefix__svg-inline--fa prefix__fa-message-outline prefix__fa-w-16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55556 1.75C2.11066 1.75 1.75 2.11066 1.75 2.55556V13.1893L3.58078 11.3586C3.72143 11.2179 3.9122 11.1389 4.11111 11.1389H13.4444C13.8893 11.1389 14.25 10.7782 14.25 10.3333V2.55556C14.25 2.11066 13.8893 1.75 13.4444 1.75H2.55556ZM0.25 2.55556C0.25 1.28223 1.28223 0.25 2.55556 0.25H13.4444C14.7178 0.25 15.75 1.28223 15.75 2.55556V10.3333C15.75 11.6067 14.7178 12.6389 13.4444 12.6389H4.42177L1.53033 15.5303C1.31583 15.7448 0.993243 15.809 0.712987 15.6929C0.432732 15.5768 0.25 15.3033 0.25 15V2.55556Z"
        fill="#6A6D81"
      />
      <path
        d="M6 6C6 6.55228 5.55228 7 5 7C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5C5.55228 5 6 5.44772 6 6Z"
        fill="#6A6D81"
      />
      <path
        d="M9 6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6Z"
        fill="#6A6D81"
      />
      <path
        d="M12 6C12 6.55228 11.5523 7 11 7C10.4477 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5C11.5523 5 12 5.44772 12 6Z"
        fill="#6A6D81"
      />
    </svg>
  );
};

export default SvgComponent;
