import { useHeaderFloorsQuery, useHeaderRoomQuery, useHeaderZoneQuery } from '@/.generated/graphql';
import { PrimaryRefresh, QuestionMenu } from '@/components';
import { PageLayoutHeader } from '@/components/PageLayout';
import { SpaceSwitcher } from '@/components/studio/SpaceSwitcher';
import { useAuthUser, useFetchSR, useSRZId } from '@/hooks';
import { reportingApi } from '@/hooks/api/reporting';
import type { RootState } from '@/redux/reducers';
import { getSingleGraphQLModel } from '@/utils/getSingleGraphQLModel';
import { gql } from '@apollo/client';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { useSuperUser } from '@/redux/hooks';
import { existsFilter } from '@/utils/filtering';
import { useRef } from 'react';

const REFRESH_QUERY_ENDPOINTS: (keyof typeof reportingApi.endpoints)[] = [
  'get24hTrend',
  'getLatest',
  'getLatestGrouped',
  'getReporting',
];
function isRefreshEndpoint(endpointName: string): boolean {
  // we don't care to typecheck this string is strictly a possible value;
  // all we care about is if it's in the list.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return REFRESH_QUERY_ENDPOINTS.includes(endpointName as any);
}

export const Header = () => {
  const { isSuperUserDashboard, selectedClient } = useSuperUser();
  const { t } = useTranslation();
  const url = useLocation();
  const { user, name } = useAuthUser();
  const { roomId, spaceId, zoneId } = useSRZId();
  const { data: spaceData, loading: spaceLoading } = useHeaderFloorsQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = getSingleGraphQLModel(spaceData?.floors);
  const { triggerRefresh } = useFetchSR(space);
  const skip = !spaceId || !roomId;
  const { data: roomData, loading: roomLoading } = useHeaderRoomQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { roomId: roomId! },
    skip,
  });
  const { data: zoneData, loading: zoneLoading } = useHeaderZoneQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { zoneId: zoneId! },
    skip: !zoneId,
  });

  const room = roomData?.rooms?.data?.[0];
  const zone = zoneData?.zones?.data?.[0];
  // Kind of a hack to get loading state
  const queries = useSelector((s) => (s as RootState).api.queries);
  const loading = Object.values(queries)
    .filter(existsFilter)
    .filter((q) => q.status === QueryStatus.pending)
    .some((q) => isRefreshEndpoint(q.endpointName ?? ''));

  const headerNameRef = useRef('');

  if (!spaceLoading && !roomLoading && !zoneLoading) {
    const newHeaderName =
      url.pathname === '/devices'
        ? (selectedClient && Object.keys(selectedClient)[0]) || name
        : roomId && room
        ? room.name
        : zoneId && zone
        ? zone.name
        : spaceId && space
        ? space.name
        : '';

    if (newHeaderName) {
      headerNameRef.current = newHeaderName;
    }
  }

  const headerName = headerNameRef.current;

  return (
    <PageLayoutHeader className="z-[9] bg-white">
      <div>
        {!isSuperUserDashboard && <p className="mb-2 text-sm uppercase text-gray-400">{name}</p>}
        <div className="flex flex-row items-center gap-3 text-3xl font-semibold">
          {isSuperUserDashboard ? (
            t('dashboards')
          ) : url.pathname === '/studio' && space ? (
            <SpaceSwitcher value={spaceId} />
          ) : (
            headerName && headerName
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {!isSuperUserDashboard && <PrimaryRefresh refreshing={loading} onClick={triggerRefresh} />}
        <QuestionMenu />
        {user && <UserMenu user={user} space={space} />}
      </div>
    </PageLayoutHeader>
  );
};

Header.queries = {
  Floors: gql`
    query HeaderFloors($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          name
          timezone
          ...UserMenuFloor
        }
      }
    }
    ${UserMenu.fragments.Floor}
  `,
  Room: gql`
    query HeaderRoom($roomId: String!) {
      rooms(ids: [$roomId]) {
        data {
          id: room_id
          name
        }
      }
    }
  `,
  Zone: gql`
    query HeaderZone($zoneId: String!) {
      zones(ids: [$zoneId]) {
        data {
          id: zone_id
          name
        }
      }
    }
  `,
};
