import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  Ref,
  memo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@/components';
import { ICommonComponentProps } from '@/types';
import { XIcon } from '@heroicons/react/solid';
import { useOnClickOutside, useCombinedRefs } from '@/hooks';
import clsx from 'clsx';

interface ISearchInputProps extends ICommonComponentProps {
  variant?: 'primary' | 'secondary';
  placeholder?: string;
  value: string;
  minimized?: boolean;
  focus?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onFocus?: () => void;
}

export const SearchInput = memo(
  forwardRef<HTMLInputElement, ISearchInputProps>(
    (
      {
        className,
        style,
        variant = 'primary',
        placeholder,
        value,
        minimized,
        focus,
        onChange,
        onBlur,
        onClear,
        onFocus,
        ...rest
      }: ISearchInputProps,
      ref?: Ref<HTMLInputElement> | undefined,
    ) => {
      const { t } = useTranslation();
      const [mini, setMini] = useState(minimized && !value);
      const containerRef = useRef<HTMLDivElement | null>(null);
      const inputRef = useCombinedRefs(ref, useRef<HTMLInputElement | null>(null));

      // Handler for expanding search box, focus then
      const handleContainerClick = useCallback(() => {
        if (!minimized || !inputRef.current) return;
        setMini(false);
        (inputRef.current as HTMLInputElement).focus();
      }, [minimized]);

      // Handler for minimizing search box
      const handleOutSideClick = useCallback(() => {
        if (!minimized || value) return;

        setMini(true);
      }, [minimized, value]);

      useOnClickOutside(containerRef, handleOutSideClick);

      useEffect(() => {
        if (focus)
          setTimeout(() => inputRef.current && (inputRef.current as HTMLInputElement).focus(), 10);
      }, [focus]);

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          data-id="search-input"
          ref={containerRef}
          className={clsx(
            'flex flex-1 cursor-pointer flex-row items-center',
            variant === 'primary' && 'rounded-full border border-gray-300 px-3 py-1',
            variant === 'secondary' && 'rounded-md bg-product-gray100 px-4 py-2',
            className,
          )}
          style={style}
          onClick={handleContainerClick}
          {...rest}
        >
          <SearchIcon className="mr-1 h-3 w-3 text-gray-400" />
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholder || t('search')}
            value={value}
            className={clsx(
              'outline-none flex-1 border-none bg-transparent py-1 text-xs font-normal',
              mini && 'w-0',
            )}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={() => {
              onFocus?.();
            }}
          />
          <XIcon
            className={clsx(
              'text-gray-500 hover:text-gray-600 h-4 w-4',
              mini && 'w-0',
              (!onClear || !value) && 'invisible',
            )}
            aria-hidden="true"
            onClick={onClear}
          />
        </div>
      );
    },
  ),
);
